$color: #00B3FF;

.Button {
    display: inline-block;
    border-width: 2px;
    border-color: white !important;
    border-style: solid;
    color: white !important;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    height: 50px;
    width: 175px;
    z-index: 1;
    border-radius: 0.5rem;
    background-color: transparent;
    margin-top:25px;
    outline:none !important;


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color:white !important;
        background-color: transparent;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: white;
        color: #3E4756;
        transition: all .3s;
        z-index: -1;
    }

    &:hover {
        color: #3E4756 !important;

        &:before {
            width: 100%;
        }
    }
}

.ButtonAlternate {
    display: inline-block;
    border-width: 2px;
    border-color: #FFDF6C !important;
    border-style: solid;
    color: #FFDF6C !important;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    height: 50px;
    width: 175px;
    z-index: 1;
    border-radius: 0.5rem;
    background-color: transparent;
    margin-top: 25px;

    &:hover {
        box-shadow: 0 0 10px 10px rgba(255, 223, 108, .1);
    }
}

.MenuButton {
    display: inline-block !important;
    color: #B1CCC9;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 100px;
    padding-top: 15px !important;
    padding-bottom: 40px !important;
    z-index: 1;
    border-radius: 0.1rem;
    background-color: transparent;
    margin-top: 25px;
    text-decoration: none !important;


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background-color: rgba(225,225,225,0.075);
        color: #3E4756;
        transition: all .3s;
        z-index: -1;
    }

    &:hover {
        color: white;

        &:before {
            height: 100%;
        }
    }
}

.LogoMenuButton {
    display: inline-block;
    color: white !important;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    height: 40px;
    width: 40px;
    margin-left: -5px;
    z-index: 1;
    border-radius: 0.1rem;
    background-color: transparent;
    margin-top: 30px;


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background-color: rgba(225,225,225,0.075);
        color: #3E4756;
        transition: all .3s;
        z-index: -1;
    }

    &:hover {
        color: white !important;

        &:before {
            height: 100%;
        }
    }
}

.SubmitButton {
    display: inline-block;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0.5rem;
    background-color: transparent !important;
    margin-top: 25px;
    color: #00A6F5 !important;
    border-color: #00A6F5 !important;
    width: 110px;
    height: 50px;
    padding:0 0 0 0 !important;


    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: white !important;
        background-color: transparent !important;
        z-index: -2;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
        background-color: #009AF2;
        color: white;
        transition: all .3s;
        z-index: -1;
    }

    &:hover {
        color: white !important;

        &:before {
            height: 100%;
        }
    }
}

.SubmitButtonAlternate {
    display: inline-block;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .2rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0.5rem;
    background-color: transparent !important;
    margin-top: 25px;
    color: #3F3F3F !important;
    border-color: #3F3F3F !important;
    width: 110px;
    height: 50px;
    padding: 0 0 0 0 !important;


    &:after {
        background-color: transparent !important;
        z-index: -2;
    }

    &:before {
        background-color: #3F3F3F;
        transition: all .3s;
        z-index: -1;
    }

    &:hover {

        &:before {
            height: 100%;
        }
    }
}

$circle-diameter: 17px;

.ThemeCircle {
    width: $circle-diameter;
    height: $circle-diameter;
    border-radius: 100%;
    transform: rotate(50deg);
    margin-top:auto;
    margin-bottom:auto;

    &:before,
    &:after {
        content: '';
        width: $circle-diameter/2;
        height: $circle-diameter;
    }
}

.ThemeCircleDefault {
    &:before {
        float: left;
        border-top-left-radius: $circle-diameter/2;
        border-bottom-left-radius: $circle-diameter/2;
        background: #007CC7;
    }

    &:after {
        float: right;
        border-top-right-radius: $circle-diameter/2;
        border-bottom-right-radius: $circle-diameter/2;
        background: white;
    }
}

.ThemeCircleAlternate {
    &:before {
        float: left;
        border-top-left-radius: $circle-diameter/2;
        border-bottom-left-radius: $circle-diameter/2;
        background: #202020;
    }

    &:after {
        float: right;
        border-top-right-radius: $circle-diameter/2;
        border-bottom-right-radius: $circle-diameter/2;
        background: #FFDF6C;
    }
}