.BlogHeading {
    font-family: 'Segoe UI', OpenSans !important;
    font-style: normal;
    font-weight: 100;
    font-size: 80px;
    text-align: center;
    width: 100%;
    color: #3E4756;
    margin-top: -20px;
}
.BlogHeading2 {
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 1.5px;
    font-family: MaisonNeue-Bold,Arial;
    text-align: center;
    color: black;
    margin-top: 10px;
}

.BlogContainer {
    margin-left: 20%;
    margin-right: 20%;
}

.BlogHeader {
    background-color: #3E4756 !important;
    height: 100px !important;
    background: #3E4756 !important;
}

.AlternateText {
    color: #ffdf6c;
}

.BlogPage {
    width: 100%;
    min-height:1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 100px 100px 100px;
    margin-top: 100px;
    margin-bottom: 100px;
    border-radius: 2rem;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 7%);
}

@media only screen and (max-width : 2500px) {
    .BlogContainer {
        margin-left: 10% !important;
        margin-right: 10% !important;
    }
}

.BlogRow {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.BlogRowCenter {
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    width:100%;
}

.BlogCol {
    width: 100%;
    cursor: pointer;
    display: inline-block;
    z-index: 3;
    margin-top: 80px;
}
    .BlogCol:hover .BlogTitle, .BlogCol:hover .BlogSubTitle {
        color: #007CC7;
    }

.BlogHighlighted {
    color: #DEA01E !important;
}

.BlogImg {
    width: 100%;
    max-height: 100%;
    margin-top:-20%;
    transition: 1s;
}

.BlogTitle {
    font-family: 'Segoe UI', OpenSans,Arial;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 2px;
    font-size: 35px;
    text-align: center;
    margin-top: 10px;
    text-decoration: none !important;
}

.Row {
    vertical-align: top;
}

.BlogSubTitle {
    font-family: Calibri,'Segoe UI', OpenSans,Arial;
    font-style: normal;
    line-height: 30px;
    letter-spacing: 1.5px;
    font-size: 22px;
    color: black;
    text-align: center;
    margin-top: 10px;
}

.BlogDate {
    font-size: 18px;
    color: #DEA01E !important;
    font-weight: 600;
}

.BlogImg:hover {
    transition: 2s;
    transform: scale(1.2) rotate(3deg);
    z-index:1;
}

.ImageContainer {
    border: solid;
    border-color: black;
    border-width: 0px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 7%);
    margin-left: 5%;
    width: 90%;
    max-height: 335px;
    display: inline-block; /* change the default display type to inline-block */
    overflow: hidden; /* hide the overflow */
    border-radius: 0.5rem;
}

.TitleImage {
    max-height: 550px !important;
}

.SmallerImage {
    width: 45% !important;
    margin-left:0px !important;
}

.BlogNavBar {
    padding:10px 25px 10px 25px;
    margin-top:10px;
    
}

.BlogUnderline {
    width: 92.5%;
    background-color: #3E4756;
    height: 2px;
    margin-top: -6px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}


.SearchContainer
{
    max-width:100%;
    margin-top:-4px;
}

.SearchCol {
    max-width: 30% !important;
    padding-right: 5% !important;
    text-align: right;
}

.OptionsCol
{
    

}

.BlogBarLink {
    font-family: 'Segoe UI', OpenSans,Arial;
    color: #B1CCC9;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .15rem;
    font-weight: 500;
    padding: 10px 50px 10px 50px;
    margin-top: -100px;
}


    .BlogBarLink:hover {
        color: #DEA01E !important;
    }

    .BlogBarLinkSelected {
        color: #DEA01E !important;
    }

.SearchControl {
    border: none !important;
    margin-top: -2px !important;
    font-family: 'Segoe UI', OpenSans,Arial !important;
    font-size: 1.1rem;
    letter-spacing: .15rem;
    font-weight: 500 !important;
}
        .SearchControl:focus {
            border: none !important;
            box-shadow:none !important;
        }

.Row
{
    display:flex;
    flex-direction:row;
}

.Center {
    justify-content: center;
}

.SearchImage
{
    width:25px;
    cursor:pointer;
}

.Social {
    width: 30px;
    
}

.BlogEntryTitle {
    font-family: MaisonNeue-Bold,Helvetica,Arial,sans-serif;
    font-size: 100px;
    line-height: 112px;
    letter-spacing: 5px;
    text-align: center;
    color: #0063AB;
    font-weight: 100;
}

.BlogEntrySummary {
    letter-spacing: 2px;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    color: #434656;
}

.BlogEntryImage {
    border: solid;
    border-color: #3E4756;
    margin-top:10px;
    border-width: 0px;
    max-height: 500px;
    max-width:100%;
}

.EntryTitleContainer
{
    margin-bottom: 50px;
}

.EntryImageCol
{
    margin-top:25px;
    margin-bottom:25px;
    text-align:center;
}

.BlogEntryImageCaption {
    font-family: MaisonNeue-Bold,Helvetica,Arial,sans-serif;
    font-size: 18px;
    margin-top: 10px;
}

.BlogEntrySubtitle {
    font-family: MaisonNeue-Bold,Helvetica,Arial,sans-serif;
    margin-top: 50px;
    color: #0063AB;
    font-size:35px;
    letter-spacing:2px;
    font-weight:500 !important;
}

.Banner {
    background-color: #3F3F3F;
    color: #DEA01E;
    font-weight: 400;
    font-size: 2vw;
    margin-top: 5%;
    text-align:center;
    letter-spacing: 2px;
    padding-top: 0.5%;
    padding-bottom: 1%;
    margin-left: 61%;
    width: 50%;
    transform: rotate(35deg);
    position: relative;
    z-index: 4;
    pointer-events: none;
}

.FadeBlog {
    transition: opacity 0.5s;
}

.FadeOutBlog {
    animation: fadeOut 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.SearchNavMobile{
    display:none !important;
}

@media only screen and (max-width : 1000px) {
    .BlogContainer {
        margin-left: 0% !important;
        margin-right: 0% !important;
    }

    .Banner {
        font-size: 2.5vw;
        padding-left:10px;
    }


    .BlogPage {
        width: 96%;
        min-height: 0px;
        margin-left: auto;
        margin-right: auto;
        padding: 5px 5px 50px 5px;
        margin-top: 10px;
        margin-bottom: 150px;
        border-radius: 0rem;
        box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 7%);
    }

    .BlogHeading{
        font-size: 60px;
    }

    .BlogBarLink {
        font-family: 'Segoe UI', OpenSans,Arial;
        color: #B1CCC9;
        cursor: pointer;
        font-size: 1.1rem;
        letter-spacing: .15rem;
        font-weight: 500;
        padding: 15px 15px 15px 15px;
        margin-top: -100px;
    }

    .SearchNavMobile {
        margin-top: 0px;
        display: block !important;
    }

    .BlogUnderline {
        margin-bottom: -10px;
        width: 87%;
    }

    .BlogCol{
        margin-top:60px;
        min-width:90%;
    }

    .CenterMobile{
        align-content:start !important;
    }

    .SmallerImage {
        width: 90% !important;
        margin-left: 0px !important;
    }

    .BlogTitle {
        line-height: 35px;
        letter-spacing: 2px;
        font-size: 20px;
        color: black;
        text-align:left;
    }

    .BlogSubTitle{
        font-weight:100;
        font-size:16px;
    }

    .BlogEntryTitle {
        font-size: 25px;
        line-height: 60px;
        letter-spacing: 3px;
        font-weight:500;
        text-align:left;
    }

    p{
        font-size:16px;
    }

    .BlogDate {
        font-size: 14px;
    }

    .BlogHeading2{
        font-size:10px;
        text-align:left;
        margin-top:-10px;
    }

    .BlogHeading2Mob{
        text-align:center !important;
    }

    .BlogImg {
        width: 110% !important;
        margin-top:-20%;
        max-height: 102% !important;
        transition: 1s;
    }

    .BlogEntrySummary {
        font-size: 18px;
        letter-spacing: 0px;
        line-height:normal;
        text-align:left;
    }

    .EntryTitleContainer {
        margin-bottom: 35px;
    }

    .BlogEntrySubtitle {
        margin-top: 35px;
        font-size: 20px;
    }

    .BlogEntryImageCaption {
        font-size: 13px;
    }

    .BlogHeader{
        display:none;
    }
    .SearchCol {
        display: none;
    }
}