@font-face {
    font-family: "OpenSans";
    src: url("./fonts/OpenSans-VariableFont_wdth,wght.ttf");
    font-style: normal;
}

.Swoosh {
    background-color: #007CC7;
    background: linear-gradient(to top left, #44b6e3 0%, #026dd6 100%) 100% no-repeat;
    transform: scaleX(-1);
    width: 100%;
    min-width: 1920px;
    position: absolute;
    top: 0px;
    z-index: -1;
}

.SwooshAlternate {
    width: 100%;
    position: absolute;
    min-width: 1920px;
    top: 0px;
    z-index: -1;
}

.Home {
    position: relative;
    overflow: hidden;
    max-width: 100% !important;
}

.Magnify {
    color: white;
    filter: invert(65%) sepia(98%) saturate(1746%) hue-rotate(185deg) brightness(106%) contrast(101%);
    width: 40px;
    padding:10px 10px 10px 10px;
    margin-top: 20px;
}

.DarkGreenFilter {
    filter: invert(52%) sepia(38%) saturate(608%) hue-rotate(128deg) brightness(91%) contrast(93%);
}

.LightSwoosh {
    width: 100%;
    position: absolute;
    min-width: 1920px;
    top: 550px;
    z-index: -1;
}

.Triangle {
    width: 110%;
    position: absolute;
    min-width: 1920px;
    top: 700px;
    z-index: -1;
}

.AlternateSwipe {
    width: 100%;
    right: auto;
    height: 400px;
    background: #707070;
    position: absolute;
    top: 1250px;
    z-index: -1;
}

.Segoe
{
    font-family:'Segoe UI', OpenSans !important;

}

.Error{
    border-color: red !important;
}

.AlternateBacking {
    background-color: transparent !important;
    box-shadow: 0 0 0px 0px rgb(0 0 0 / 0%) !important;
    margin-bottom: 0px !important;
}

.InformationIconAlternate {
    border: solid !important;
    border-color: #ffdf6c !important;
    border-width: 2px !important;
    border-radius: 1rem;
    box-shadow: 0 0 15px 15px rgb(0 0 0 / 3%) !important;
}

.White {
    color: white !important;
}

.DarkGrey {
    color: #202020 !important;
}

.Alternate {
    background-color: #707070;
}

.ButtonAlternate {
    color: #FFDF6C !important;
    outline:none !important;
}

.AlternateGrey {
    color: #707070 !important;
}

.LightSwooshAlternate {
    filter: brightness(0) saturate(100%) invert(22%) sepia(0%) saturate(3260%) hue-rotate(43deg) brightness(106%) contrast(99%);
}

.FooterContainer {
    width: 100%;
    height: 300px;
    background-color: #0063AB;
    padding-top: 75px;
}

.FooterAlternate {
    background-color: #202020;
}

.LightSwoosh2 {
    width: 103%;
    position: absolute;
    min-width: 1920px;
    top: 1750px;
    z-index: -1;
    transform: scaleX(-1);
}

.Title {
    color: white;
    font-family: 'Segoe UI', OpenSans;
    font-size: 100px;
    font-weight: 700;
    letter-spacing: -2px;
    margin-top: 50px;
    margin-bottom: 0px;
    margin-right: 0px;
    padding-right: 0px;
    width: fit-content;
}

.SubTitle {
    color: white;
    font-family: Calibri, OpenSans;
    font-size: 25px;
    font-weight: 100;
    
    letter-spacing: 5px;
    margin-top:0px;
    margin-left: 5px;
}

.SubTitleMicro {
    color: white;
    font-family: Calibri, OpenSans;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 2px;
    margin-left: 5px;
    margin-bottom:20px !important;
    margin-top:-20px !important;
}

.CentralContainer
{
    margin-left:20%;
    margin-right:20%;
}

.NavBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Menu {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.NavItem {
    padding: 0px 20px 0px 20px;
    text-align: center;
    justify-content:center;
}

.Configurator {
    width: 50%;
    position: absolute;
    min-width: 400px;
    top: 200px;
    left:40%;
    z-index: -1;
}

.Logo
{
    width:40px;
}

.CentralInformation {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align:center;
}

.InformationContainer {
    padding: 0px 20px 0px 20px;
    text-align: center;
    width: 425px !important;
    min-width: 425px !important;
    max-width: 650px !important;
    margin-left:auto !important;
    margin-right:auto !important;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 50px;
    padding: 20px 20px 20px 20px;
    transition: all 2s;
}

.InformationLarger {
    transform: scale(1.1);
    transition: all 0.8s;
    background-color: rgba(255,255,255,0.6);
    border-radius: 4rem;
    box-shadow: 0 0 15px 15px rgb(0 0 0 / 3%);
}

.InformationSmaller {
    transform: scale(0.9);
    transition: all 0.8s;
}

.DarkHeader {
    font-family: 'Segoe UI', OpenSans;
    font-size: 30px;
    font-weight: 700;
    color: #434656;
    margin-bottom: 0px;
    margin-top: 0px;
}

.BlueHeader {
    font-family: 'Segoe UI', OpenSans;
    font-size: 30px;
    font-weight: 700;
    color: #007CC7;
    margin-bottom: 0px;
    margin-top: 0px;
}

p {
    font-family: Calibri, OpenSans;
    font-size: 22px;
    font-weight: 400;
    color: #434656;
    margin-bottom: 0px !important;
}

.SubtitleContainer
{
    display:flex;
    flex-direction:row;
    justify-content:center;
}

.InformationIcon {
    width: 100px;
    margin-bottom: 15px;
}

.LG
{
    font-size: 50px;
}

.AimContainer {
    margin-top: 35%;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}


.WhoContainer {
    margin-top: 0px;
    text-align: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /*
        border-radius: 1rem;
        padding: 40px 40px 40px 40px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 4%);*/
    margin-bottom: 200px;
}

.InformationCardsContainer {
    margin-top: 100px;
    margin-bottom: 50px;
}


.SocialIcon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor:pointer;
}

.PictureOfMe {
    width: 250px;
    border-radius: 1rem;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 8%);
    margin-top: 10px;
}

.PictureOfMeAlternate {
    border-radius: 0.5rem;
    border-width: 2px;
    border-color: #707070;
}

.OuterImage {
    width: 270px;
    height: 270px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    margin-bottom: 50px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 0%);
    transform: rotate(0deg);
}

.ContactHeaderContainerDesktop {
    display: block;
}

.ContactHeaderContainer {
    display: none;
}
.ContactHeader {
    width: 100%;
    height: 400px;
    background: linear-gradient(to bottom left, #026dd6 0%, #44b6e3 100%) 100% no-repeat;
    position: absolute;
    top: 0px;
    z-index:-1;
}

.ContactHeaderAlternate {
    background: linear-gradient(to bottom left, #3F3F3F 0%, #202020 100%) 100% no-repeat !important;
}

.AlternateNavBarMobile {
    background: linear-gradient(to bottom left, #202020 0%, #202020 100%) 100% no-repeat !important;
}

.AlternateOrange {
    color: #BF5747;
}

.OrangeFilter {
    filter: invert(97%) sepia(16%) saturate(6011%) hue-rotate(311deg) brightness(112%) contrast(103%);
    transition: all 0.75s;
}

.AlternateFilter {
    filter: invert(74%) sepia(43%) saturate(0%) hue-rotate(62deg) brightness(98%) contrast(104%);
    filter: invert(48%) sepia(5%) saturate(94%) hue-rotate(22deg) brightness(89%) contrast(87%);
}

.ContactTitle {
    margin-top: 50px !important;
    font-size:75px;
}
.ContactSubtitle {
    letter-spacing:3px;
}

.FormContainer {
    margin-top: 200px;
    width: 75%;
    min-width:500px;
    max-width:750px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 50px 50px 50px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 5%);
    border-radius:1rem;
    margin-bottom:100px;
}

.ControlText {
    font-family: 'Segoe UI', OpenSans;
    letter-spacing: 1px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 3%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.FormTitle {
    font-family: 'Segoe UI', OpenSans;
    letter-spacing: 1px;
}

.Blue {
    color: #007CC7;
}

.ContactTitleContainer
{
    margin-bottom:45px;
}

.FormSpacing
{
    margin-top:50px;
}


.SubmitButtonContainer
{
    width:100%;
    text-align:center;
}

.SubmitButton {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    font-size: 1.1rem;
    letter-spacing: .1rem;
    transition: all .5s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0.5rem;
    background-color: transparent !important;
    margin-top: 25px;
    color: #00A6F5 !important;
    border-color: #00A6F5 !important;
    width: 100px;
    height: 50px;
    padding: 0 0 0 0 !important;
}

.Column
{
    display:flex;
    flex-direction:column;
    margin-left:40px;
    margin-right:40px;
}

.FooterText {
    color: white !important;
    font-family: Calibri, OpenSans;
    font-size: 22px;
    font-weight: 100;
    letter-spacing: 2px;
}

.FooterLink {
    cursor: pointer;
    
}
    .FooterLink:hover {
        text-decoration: underline !important;
    }
.FooterTitle {
    color: white;
    font-family: 'Segoe UI', OpenSans;
    font-size: 65px;
    font-weight: 700;
    letter-spacing: -2px;
    margin-top: 15px;
    margin-right: 0px;
    line-height: 100%;
    padding: 0px 8px 5px 5px;
    width: fit-content;
    border: solid;
    border-width: 1px;
    border-color: white;
}

.FooterTitleAlternate {
    border-color: #FFDF6C;
}

.Email{
    margin-top:20px;
    font-size:15px !important;
}

.ColumnSeparator {
    height: 100%;
    width: 2px;
    background-color: white;
}

.RowSeparator {
    height: 1px;
    width: 100%;
    background-color: white;
}

.FooterSubTitle {
    font-family: Calibri, OpenSans;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 700 !important;
    letter-spacing: 2px;
    color: white;
}

.FooterEmail {
    font-family: Calibri, OpenSans;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 100 !important;
    letter-spacing: 2px;
    color: white !important;
}

.Selected
{
    font-weight:400;
    color:white !important;
}

.LoadingContainer {
    margin-top: 200px;
    width: 75%;
    height: 750px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.LoadingGif
{
    margin-top:150px;
    width:300px;
    margin-bottom:auto;
}

.Sending {
    font-family: 'Segoe UI', OpenSans;
    letter-spacing: 1px;
    font-style: italic;
}

.ContactSuccess {
    font-size:50px;
    letter-spacing: 1px;
}

.BackButton
{
    margin-top:30px !important;
}

.SuccessContainer {
    padding: 50px 50px 50px 50px;
    box-shadow: 0 0 25px 15px rgb(0 0 0 / 5%);
    border-radius: 1rem;
    height:100% !important;
    margin-bottom:200px;
    width:50%;
    margin-top:300px;
}

.ThemeButton {
    border-width: 0px;
    border-radius: 0.4rem !important;
}

.ThemeContainer{
    position: absolute;
    left:8% !important;
}

.ThemeOption {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    width: 90px;
    background: transparent;
    text-align:left;
    padding-left:10px;
    border: none !important;
    color: rgba(255,255,255,0.6);
    outline:none !important;
}

.ThemeOption:hover {
    color: rgba(255,255,255,0.9);
}

.ThemeOptionContainer {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    border-radius: 0.3rem;
    border: solid;
    border-width: 0px;
    background-color: rgba(255,255,255,0.2);
    margin-bottom: 5px;
    margin-top: -100px;
    transition: all 2s;
}

.ThemeOptionContainerHover {
    margin-top: 0px !important;
    transition: all 0.75s;
}

.ThemeOptionsContainer {
    margin-top:5px;
    overflow: hidden;
}

.MenuButtonImage {
    width: 30px;
}

.MenuButtonImageLarge {
    width: 40px;
    margin-top:-4px;
}

.MenuButtonImageContainer {
    width: 90px;
    height: 60px;
    padding: 0px 0px 0px 0px;
    margin-top: 12px;
    border-radius: 0.2rem;
}

.BlueFilter {
    filter: brightness(0) saturate(100%) invert(43%) sepia(63%) saturate(6349%) hue-rotate(183deg) brightness(92%) contrast(101%);
    filter: brightness(0) saturate(100%) invert(69%) sepia(93%) saturate(1391%) hue-rotate(165deg) brightness(91%) contrast(95%);
}

.NavItemMobile {
    text-align: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;
}

.NavBarContainerMobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 55px;
    background: linear-gradient(to bottom left, #026dd6 0%, #026dd6 100%) 100% no-repeat;
    z-index: 20;
    display: none;
}

.MenuMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width:100%;
}

.MobileTitle {
    color: white;
    font-family: 'Segoe UI', OpenSans;
    font-size: 100px;
    display: none;
    font-weight: 700;
    letter-spacing: -2px;
    padding-right: 5px;
    border: 2px solid white;
}

.MobileTitleContainer{
    margin-top:50px;
    margin-bottom:30px;
    width:175px;
    margin-left:auto;
    margin-right:auto;

}


@media only screen and (max-width : 1000px) {
    .Selected {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        transition: all 0.75s;
    }

    .NavBarContainer{
        display:none;
    }

    .NavBarContainerMobile{
        display:block;
    }

    .Swoosh, .SwooshAlternate {
        display: none;
    }

    .Title{
        display:none;
    }

    .pulse {
        box-shadow: 0 0 0 rgba(255,255,255, 0.4);
        animation: pulse 2s 5;
    }

    @-webkit-keyframes pulse {
        0% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
        }

        70% {
            -webkit-box-shadow: 0 0 0 30px rgba(255,255,255, 0);
        }

        100% {
            -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
    }

    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.5);
            box-shadow: 0 0 0 0 rgba(255,255,255, 0.5);
        }

        70% {
            -moz-box-shadow: 0 0 0 16px rgba(255,255,255, 0);
            box-shadow: 0 0 0 16px rgba(255,255,255, 0);
        }

        100% {
            -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
            box-shadow: 0 0 0 0 rgba(255,255,255, 0);
        }
    }

    .BallRollingMobile{
        font-size:25px;
    }

    .Configurator, .FooterContainer, .ContactHeaderMobile {
        display: none;
    }

    .ContactHeaderContainerDesktop{
        display:none;
    }

    .ContactHeaderContainer {
        background: linear-gradient(to bottom left, #026dd6 0%, #44b6e3 100%) 100% no-repeat;
        padding-top: 25px !important;
        padding-bottom: 30px !important;
        padding-left: 5%;
        padding-right: 5%;
        display: block;
        height: 100%;
        background: linear-gradient(to bottom, #026dd6 0%, #44b6e3 100%) 100% no-repeat;
    }


    .ContactSubtitle {
        margin-top: 40px !important;
        margin-bottom:5px !important;
    }

    .ContactHeaderContainerAlternate {
        background: linear-gradient(to bottom left, #3F3F3F 0%, #202020 100%) 100% no-repeat !important;
    }

    .CentralContainer {
        margin-left: 5%;
        margin-right: 5%;
    }

    .TouchButtonMobile{
        margin-top:50px !important;
    }

    .HomeHeaderContainer {
        text-align: center;
        width: 100%;
    }

    .HomeHeaderMobile {
        width: 100%;
        height: 550px;
        background: linear-gradient(to bottom, #026dd6 0%, white 100%) 100% no-repeat;
        position: absolute;
        top: 0px;
        z-index: -1;
    }

    .HomeHeaderMobileAlternate {
        background: linear-gradient(to bottom, #202020 0%, #3F3F3F 100%) 100% no-repeat;
        height: 425px;
        box-shadow: 0 0 50px 15px rgb(0 0 0 / 50%);
    }

    .HomeHeaderMobileAlternateContact {
        height: 300px !important;
    }

    .MobileTitle{
        display:block;
    }

    .BlueHeader, .DarkHeader{
        font-weight:300;
        font-size:25px;
    }

    .AimContainer {
        margin-top: 120px;
    }

    .TextMobile {
        font-weight: 400;
        font-size: 18px;
    }

    .InformationContainer {
        min-width: 90% !important;
        margin-bottom:0px;
    }

    .ContactTitle{
        font-size: 35px;
        text-align:center;
        margin-top:0px !important;
    }

    .SuccessContainer {
        padding: 20px 20px 20px 20px;
        box-shadow: 0 0 25px 15px rgb(0 0 0 / 5%);
        border-radius: 1rem;
        height: 100% !important;
        width:90% !important;
        margin-top: 15% !important;
    }

    .Email {
        margin-top: 20px !important;
        text-align: center;
        font-size: 15px !important;
        letter-spacing: 2px !important;
    }

    .ContactSubtitle {
        font-size: 16px;
        text-align: center;
        margin-top:30px !important;
    }

    .InformationCardsContainer{
        margin-top: 75px;
    }

    .ContactHeader{
        height:275px;
    }

    .FormContainer {
        margin-top: 0px !important;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 25px 50px 25px;
        box-shadow: 0 0 0px 0px rgb(0 0 0 / 5%);
        border-radius: 1rem;
        margin-bottom: 40px;
    }

    .LoadingContainer {
        margin-top: 50px;
        width: 75%;
        height: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .WhoContainer {
        margin-top: 100px;
        margin-bottom: 125px;
    }


    .LoadingGif {
        margin-top: 25%;
        width: 75%;
        margin-bottom: auto;
    }

    .MobileAboutMe{
        text-align:left;
        font-size:35px !important;
    }

    .LG {
        font-size: 40px;
    }

    .SocialIcon {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
    }

    .OuterImage {
        margin-bottom: 45px;
        margin-top:-40px;
    }

    .ThemeContainer{
        display:block;
        width:100%;
    }

    .ThemeText{
        color:white;
    }
}